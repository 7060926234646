<template>
  <v-row justify="center">
    <v-carousel
      cycle
      :show-arrows="false"
      hide-delimiter-background
      delimiter-icon="mdi-minus"
      height="auto"
      v-model="carouselIndex"
    >
      <v-carousel-item
        v-for="step in steps"
        :key="step"
        :src="path"
      >
      </v-carousel-item>
    </v-carousel>
    <div class="text">
      <h2>{{ $t(`registration.info.${carouselIndex + 1}.title`) }}</h2>
      <p>{{ $t(`registration.info.${carouselIndex + 1}.desc`) }}</p>
    </div>
  </v-row>
</template>

<script>
import { range } from '@/utils';

export default {
  name: 'Info',
  data() {
    return {
      steps: range(0, 2),
      carouselIndex: 0,
    };
  },
  mounted() {
    this.$store.dispatch('registration/setFormValidity', true);
  },
  computed: {
    imageSize() {
      if (this.$vuetify.breakpoint.xs) {
        return '@1x';
      }
      if (this.$vuetify.breakpoint.sm) {
        return '@2x';
      }
      return '@3x';
    },
    path() {
      return require(`../../../public/img/signup/${this.carouselIndex + 1}${this.imageSize}.png`);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-carousel {
  width: 70%;
  max-height: 50vh;
}

.text {
  text-align: center;
  padding-top: 1em;
}
</style>
